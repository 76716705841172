import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getDatabase } from "firebase/database";

import { getAuth } from "firebase/auth"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBDzJvV8hgmiulHZgEaWbG_ky4Cj4L_VCo",
  authDomain: "vjm-properties.firebaseapp.com",
  projectId: "vjm-properties",
  storageBucket: "vjm-properties.appspot.com",
  messagingSenderId: "253607450054",
  appId: "1:253607450054:web:6289160039f93122694ec7",
  measurementId: "G-HGTKEE4VHM"
});


export const db = getDatabase(app)
export const auth = getAuth(app);
