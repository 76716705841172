import React, { Suspense, lazy } from "react";
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import './styles/style.css';
import { useCookies } from 'react-cookie';
import { auth } from "./firebase/firebase";

const Landingpage = lazy(() => import('./components/Landingpage'))
const Login = lazy(() => import('./components/Login'))
const AdminUI = lazy(() => import('./components/AdminUI'))
const Contact = lazy(() => import('./components/Contact'))
const Properties = lazy(() => import('./components/Properties'))
const PropertiesDetails = lazy(() => import('./components/PropertyDetails'))


function App() {
  const [cookies] = useCookies(['user'])

  function PrivateRoute({ children }) {
    return cookies.User || auth.currentUser? children : <Navigate to="/login" />;
  }
  return (
    <BrowserRouter>
      <Suspense fallback={
              <div className="middle-fix" >
                  <div className="flex-default-center-xy">
                    <HashLoader color={"#533c9f"} loading={true} size={80} speedMultiplier="1.4" /><br />
                  </div>
              <div className="pad-y-sm"><p><b></b></p></div></div>}>
                
        <Routes>
            
            <Route path="/" element={<Landingpage />} />
            <Route path="/login" element={<Login />} />
            <Route
                path="/adminUI"
                element={
                  <PrivateRoute>
                    <AdminUI />
                  </PrivateRoute>
                }
              />
            <Route path="/contact" element={<Contact />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/propertyDetails" element={<PropertiesDetails />} />
            
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
